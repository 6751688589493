import React from "react";
import { graphql } from "gatsby";

import { Hero, LinkBox, LinkBoxProps } from "../components";
import type { HeroProps } from "../components/types";
import { DefaultLayout, SidebarLayout, SidebarBody } from "../layouts";

export type NewsPageProps = {
  hero: HeroProps;
  body: string;
  title: string;
  sidebar: {
    boxes: LinkBoxProps[];
  };
};

export const NewsPageTemplate = ({ hero, body, sidebar, title }: NewsPageProps) => (
  <>
    <Hero {...hero} />
    <SidebarLayout
      body={
        <SidebarBody>
          <h3>{title}</h3>
          <div dangerouslySetInnerHTML={{
            __html: body,
          }} />
        </SidebarBody>
      }
      sidebar={
        sidebar &&
        sidebar.boxes.length > 0 &&
        sidebar.boxes.map((box: LinkBoxProps, index: number) => (
          <LinkBox {...box} key={index} />
        ))
      }
    />
  </>
);

export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        hero {
          showBox
          boxText
          image
        }
      }
    }
  }
`;

export default ({ data }) => (
  <DefaultLayout>
    <NewsPageTemplate body={data.page.html} title={data.page.title} {...data.page.frontmatter} />
  </DefaultLayout>
);
